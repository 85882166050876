import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="address-form"
export default class extends Controller {
  static targets = ["toggleable", "requiredField", "searchAddressError"]
  static values = { visible: Boolean }

  connect() {
    this.setVisibleState()
  }

  setVisibleState() {
    // Initial setting of visibility based on the static visible value
    if (this.visibleValue) {
      this.toggleableTargets.forEach(el => {
        el.classList.toggle("d-none")
      });

      this.requiredFieldTargets.forEach(el => {
        el.required = !el.required 
      });
    }
  }

  toggle(event) {
    event.preventDefault();
    // Toggle visibility
    this.toggleableTargets.forEach(el => {
      el.classList.toggle("d-none")
    })

    // Toggle required attribute for all required fields
    this.requiredFieldTargets.forEach(el => {
      el.value = ""; // Clear the field's value if it is being hidden
      el.required = !el.required
    })

    this.searchAddressErrorTarget.classList.add("d-none");
  }
}
