import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscriber-form"
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-end", (event) => {
      const { success, fetchResponse, error } = event.detail;

      if (success) {
        // When the submission is successful, check the status code of the fetchResponse
        if (fetchResponse.statusCode === 200) {
            alert("Subscription successful!");
        } else {
            // Handle other success status codes if necessary
        }
      } else {
        // Handle the failure case
        if (fetchResponse && fetchResponse.statusCode === 422) {
          alert("Subscription failed. Please try again.");
        } else {
          // General error handling
          alert("An error occurred. Please try again.");
        }
      }
    });
  }
}
