import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="category"
export default class extends Controller {
  static targets = ["link"]

  connect() {
  }

  toggleClass(event) {
    this.linkTargets.forEach((element) => {
      element.classList.remove('category-link-active');
    });

    event.currentTarget.classList.add('category-link-active');
  }
}
