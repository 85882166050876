function changeDate(event, path) {
    event.preventDefault();
    window.location.href = path;
  }

async function handleSlotClick(button, endpoint) {
    if (button.classList.contains("selected")) {
        clearHiddenFields();
        unselectSlot(button, endpoint);
    } else {
        unselectAllSlots(endpoint).then(function() {
            let slotTime = button.getAttribute('data-slot');
            updateHiddenFields(slotTime);
            createTempAppointment(slotTime, endpoint)
            .then(function() {
                selectSlot(button);
            })
            .catch(function(error) {
                console.error('Error creating temporary booking:', error);
                if (error.message === 'Slot not available') {
                    alert('This slot is no longer available. The page will now be refreshed.');
                    location.reload();
                } else {
                    alert('An error occurred. Please try again.');
                }
            });
        });
    }
}

function createTempAppointment(slot_time, endpoint) {
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
            slot_time: slot_time
        })
    })
    .then(function(response) {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(function(data) {
        if (data.status == 'ok') {
        // Everything worked fine.
        return data.slot_time;
        } else {
        // There was an application-level error.
        throw new Error(data.message);
        }
    });
}

function selectSlot(button) {
    button.classList.add("selected");
    document.getElementById('slot-time-error').classList.add('d-none');
}

async function unselectSlot(button, endpoint) {
    var slot_time = button.dataset.slot;
    if (slot_time) {
        await cancelTempAppointment(slot_time, endpoint)
        .then(function(response) {
            button.classList.remove("selected");
        })
        .catch(function(error) {
            console.error('Error cancelling temporary slot_time:', error);
        });
    }
}

function cancelTempAppointment(slot_time, endpoint) {
    return fetch(endpoint, {
        method: 'DELETE',
        headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
        slot_time: slot_time
        })
    })
    .then(response => {
        console.log(response);
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json();
    })
}

async function unselectAllSlots(endpoint) {
    document.querySelectorAll(".time-slot.selected").forEach(async (element) => {
        await unselectSlot(element, endpoint);
    });
}

function updateHiddenFields(slotTime) {
    document.getElementById('listing_slot_time').value = slotTime;
}

function clearHiddenFields() {
    document.getElementById('listing_slot_time').value = '';
}

document.addEventListener('turbo:load', function() {
    // $('[data-toggle="tooltip"]').tooltip();

    const slotsContainer = document.getElementById('slots-container');

    if (slotsContainer) {
        slotsContainer.addEventListener('click', function(event) {
            if (event.target.matches('.time-slot')) {
                handleSlotClick(event.target, '/listings/temp');
            }
        });
    }
});