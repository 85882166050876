import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="swiper"
export default class extends Controller {
  async connect() {
    const Swiper = (await import('swiper')).default;

    this.swiper = new Swiper(this.element, {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      simulateTouch: true,
      // Additional Swiper options...
    });
  }

  disconnect() {
    if (this.swiper && !this.swiper.destroyed) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }
}
