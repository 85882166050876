import { Controller } from "@hotwired/stimulus"
import { toggleButtonState } from "../utils/buttons";
import { performJsonFormRequest } from "../utils/fetchUtils";

// Connects to data-controller="form-submission"
export default class extends Controller {
  static targets = ["phoneNumberInput", "phoneNumberError", "verifiedText", "singleImagePreview", "singleImageError", "multipleImagesError", "searchAddress", "searchAddressError", "highlightsError", "needsPhotoServiceTrue", "needsPhotoServiceFalse", "photoServiceError"]
  static values = {
    pushToDatalayer: Boolean,
  }

  multipleImages = [];

  connect() {
    this.boundHandleSingleImageUpdated = this.handleSingleImageUpdated.bind(this);
    this.boundHandleMultipleImagesUpdated = this.handleMultipleImagesUpdated.bind(this);

    document.addEventListener('imageUpload:singleImageUpdated', this.boundHandleSingleImageUpdated);
    document.addEventListener('imageUpload:multipleImagesUpdated', this.boundHandleMultipleImagesUpdated);
  }
  
  disconnect() {
    document.removeEventListener('imageUpload:singleImageUpdated', this.boundHandleSingleImageUpdated);
    document.removeEventListener('imageUpload:multipleImagesUpdated', this.boundHandleMultipleImagesUpdated);
  }

  handleSingleImageUpdated(event) {
    // Update the controller's state or perform actions based on the updated files
    this.singleImage = event.detail.file;
  }

  handleMultipleImagesUpdated(event) {
    // Update the controller's state or perform actions based on the updated files
    this.multipleImages = event.detail.files;
  }

  saveAsDraft(event) {
    this.element.querySelectorAll('.form-control[required]').forEach((input) => {
      // Here, you specify the IDs of inputs that should remain required
      const requiredIds = ['listing_search_address', 'listing_area', 'listing_district'];
      
      if (!requiredIds.includes(input.id)) {
        input.removeAttribute('required');
      }
    });
  }

  handleSubmit(event) {
    const form = this.element;

    this.submitButton = event.submitter || form.querySelector('.confetti-button');
    this.submitButton.disabled = true;

    // Specific buttons logic
    if (this.submitButton.id === 'approve-button') {
      return;
    }

    event.preventDefault(); // Prevent the default form submission

    if (!this.validateAddress()) {
      return false;
    }

    if (!this.validatePhoneNumber()) {
      return false;
    }

    if (!this.validateImages()) {
      return false;
    }

    this.processFormSubmission();
  }

  processFormSubmission() {
    // Logic to process form submission, possibly including fetch API call
    // Handle enabling/disabling submit button, showing/hiding error messages, etc.

    // Example of fetch API call
    const formData = new FormData(this.element);

    const highlightIds = formData.getAll('listing[highlight_ids][]').filter(id => id.trim() !== '');

    if (highlightIds.length === 0 && this.submitButton.id !== 'save-as-draft-button') {
      this.displayErrorAndScroll(this.highlightsErrorTarget);
      return false;
    } else {
      this.highlightsErrorTarget.classList.add('d-none');
    }

    const action = this.element.getAttribute('action');
    const method = this.element.getAttribute('method');

    if (this.submitButton.id === 'save-as-draft-button') {
      formData.append('save_action', 'draft');
    }

    this.handleSingleImage(formData, 'listing', 'main_photo');
    this.handleMultipleImages(formData, 'listing', 'other_photos');

    performJsonFormRequest(action, method, formData, this.onSuccess.bind(this), this.onError.bind(this));
  }

  handlePostSubmit(event) {
    const form = this.element;

    this.submitButton = event.submitter || form.querySelector('.confetti-button');
    this.submitButton.disabled = true;

    event.preventDefault();

    this.multipleImages = this.multipleImages.filter(file => file !== null);

    if (this.multipleImages.length === 0) {
      this.displayErrorAndScroll(this.multipleImagesErrorTarget);
      return false;
    } else {
      this.multipleImagesErrorTarget.classList.add('d-none');
    }

    this.processPostSubmission();
  }

  processPostSubmission() {
    const formData = new FormData(this.element);
    const action = this.element.getAttribute('action');
    const method = this.element.getAttribute('method');

    this.handleMultipleImages(formData, 'post', 'images');

    performJsonFormRequest(action, method, formData, this.onSuccess.bind(this), this.onError.bind(this));
  }

  handleCategorySubmit(event) {
    const form = this.element;

    this.submitButton = event.submitter || form.querySelector('.confetti-button');
    this.submitButton.disabled = true;

    event.preventDefault();

    this.processCategorySubmission();
  }

  processCategorySubmission() {
    const formData = new FormData(this.element);
    const action = this.element.getAttribute('action');
    const method = this.element.getAttribute('method');

    this.handleSingleImage(formData, 'category', 'image');

    performJsonFormRequest(action, method, formData, this.onSuccess.bind(this), this.onError.bind(this));
  }

  handleListingSubmit(event) {
    const form = this.element;

    this.submitButton = event.submitter || form.querySelector('.confetti-button');
    this.submitButton.disabled = true;

    event.preventDefault(); // Prevent the default form submission

    if (!this.validatePhotoService()) {
      return false;
    }

    if (!this.validateAddress()) {
      return false;
    }

    if (!this.validatePhoneNumber()) {
      return false;
    }

    if (this.needsPhotoServiceFalseTarget.checked && !this.validateImages()) {
      return false;
    }

    this.processListingSubmission();
  }

  processListingSubmission() {
    const formData = new FormData(this.element);
    const action = this.element.getAttribute('action');
    const method = this.element.getAttribute('method');

    if (this.needsPhotoServiceFalseTarget.checked) {
      this.handleSingleImage(formData, 'listing', 'main_photo');
      this.handleMultipleImages(formData, 'listing', 'other_photos');
    }

    performJsonFormRequest(action, method, formData, (response) => { window.location.href = response.redirect_url; }, this.onError.bind(this));
  }

  validateAddress() {
    if (this.searchAddressTarget.classList.contains('search-address-invalid') && this.searchAddressTarget.required) {
      this.displayErrorAndScroll(this.searchAddressErrorTarget);
      return false;
    }

    this.searchAddressErrorTarget.classList.add('d-none');
    return true;
  }

  validatePhoneNumber() {
    if (!this.hasPhoneNumberErrorTarget) return true;

    if (this.hasPhoneNumberInputTarget && !this.phoneNumberInputTarget.value){
      this.displayErrorAndScroll(this.phoneNumberErrorTarget, this.phoneNumberErrorTarget.dataset.phoneNumberErrorMessage);
      return false;
    } 

    if (this.hasVerifiedTextTarget && this.verifiedTextTarget.classList.contains('d-none')) {
      this.displayErrorAndScroll(this.phoneNumberErrorTarget, this.phoneNumberErrorTarget.dataset.verificationErrorMessage);
      return false;
    }

    this.phoneNumberErrorTarget.textContent = '';
    return true;
  }

  validateImages() {
    // Main photo validation
    if (this.singleImagePreviewTarget.children.length === 0 && this.submitButton.id !== 'save-as-draft-button') {
      this.displayErrorAndScroll(this.singleImageErrorTarget);
      return false;
    }

    this.singleImageErrorTarget.classList.add('d-none');

    // Other photos validation
    this.multipleImages = this.multipleImages.filter(file => file !== null); // Assuming this is managed in the controller
    if (this.multipleImages.length < 2 && this.submitButton.id !== 'save-as-draft-button') {
      this.displayErrorAndScroll(this.multipleImagesErrorTarget);
      return false;
    }

    this.multipleImagesErrorTarget.classList.add('d-none');
    return true;
  }

  validatePhotoService() {
    if (!this.needsPhotoServiceTrueTarget.checked && !this.needsPhotoServiceFalseTarget.checked) {
      this.displayErrorAndScroll(this.photoServiceErrorTarget);
      return false;
    } 

    this.photoServiceErrorTarget.classList.add('d-none');
    return true;
  }

  handleSingleImage(formData, modelName, photoParamName) {
    const photoKey = `${modelName}[${photoParamName}]`;

    formData.delete(photoKey);

    if (this.singleImage) {
      formData.append(photoKey, this.singleImage);
    }
  }

  handleMultipleImages(formData, modelName, photoParamName) {
    const photoKey = `${modelName}[${photoParamName}][]`;
    const photoOrderKey = `${modelName}[photo_order][]`;

    formData.delete(photoKey);

    let newFileCounter = 0;

    this.multipleImages.forEach((file, i) => {
      if (typeof file === 'string') {
        formData.append(photoKey, file);
        formData.append(photoOrderKey, file);
      } else {
        formData.append(photoKey, file);
        formData.append(photoOrderKey, 'new_' + newFileCounter++);
      }
    });
  }

  onSuccess(response) {
    this.submitButton.classList.add('complete');
    this.submitButton.classList.remove('loading');
    // Trigger any visual effects and redirect
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('initBurst', { 
        bubbles: true, 
        detail: { buttonId: this.submitButton.id }
      }));
    
      setTimeout(() => {
        if (this.pushToDatalayerValue) {
          dataLayer.push({'event': 'save_self_listing_button_click'});
        }
        window.location.href = response.redirect_url;
      }, 2000);
    }, 320);
  }

  onError(error) {
    console.error('Error:', error);
    toggleButtonState(this.submitButton, false);
  }

  displayErrorAndScroll(errorTarget, errorMessage) {
    errorTarget.classList.remove('d-none');

    if (errorMessage) {
      errorTarget.textContent = errorMessage;
    }

    toggleButtonState(this.submitButton, false);
    errorTarget.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }
}
