import { Controller } from "@hotwired/stimulus"
import { fetchAndDisplayModal } from '../utils/modal_helpers';

// Connects to data-controller="confetti-redirect"
export default class extends Controller {
  static values = {
    redirectPath: String,
    modalType: String,
    buttonId: String
  }
  
  connect() {
    this.handleSuccess();
  }

  handleSuccess() {
    var button = document.getElementById(this.buttonIdValue);

    button.classList.add('complete');
    button.classList.remove('loading');

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('initBurst', { 
        bubbles: true, 
        detail: { buttonId: button.id }
      }));

      setTimeout(() => {
        if (this.hasRedirectPathValue) {
          window.location.href = this.redirectPathValue;
        } else {
          fetchAndDisplayModal(this.modalTypeValue);
        }
      }, 2000);
    }, 320);
  }
}
