import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="photo-service"
export default class extends Controller {
  static targets = ["photoFields", "dateTimeField", "confirmationMessage", "error"]

  toggle(event) {
    this.errorTarget.classList.add('d-none');
    const needsService = event.target.value === 'true';
    this.photoFieldsTarget.classList.toggle('d-none', needsService);
    this.confirmationMessageTarget.classList.toggle('d-none', !needsService);
  }

  toggleDateTimePicker(event) {
    const shouldShow = event.target.value === 'true';
    const textField = this.dateTimeFieldTarget.querySelector('input');

    if (shouldShow) {
      this.dateTimeFieldTarget.classList.remove('d-none');
      textField.required = true;
    } else {
      this.dateTimeFieldTarget.classList.add('d-none');
      textField.required = false;
      textField.value = '';
    }
  }
}
