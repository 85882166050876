import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static values = { id: String }

  connect() {
    if (this.hasIdValue && this.idValue !== '') {
      this.updateActiveTab(this.idValue.replace('-pane', ''));
      this.updateActiveTabContent(this.idValue);
    }
  }

  updateActiveTab(activeTabId) {
    let counter = 0;
    document.querySelectorAll('ul.slider-tabs li a').forEach((tab, index) => {
      if (tab.id === activeTabId) {
        counter = index;
        tab.classList.add('active');
        tab.setAttribute('aria-selected', 'true');
      } else {
        tab.classList.remove('active');
        tab.setAttribute('aria-selected', 'false');
      }
    });

    const slider = document.querySelector('.slider');
    if (slider) {
      slider.style.transform = `translateX(${100 * counter}%)`;
    }
  }

  updateActiveTabContent(activeTabPaneId) {
    document.querySelectorAll('.slider-tabs-content .tab-pane').forEach((pane) => {
      if (pane.id === activeTabPaneId) {
        pane.classList.add('show', 'active');
      } else {
        pane.classList.remove('show', 'active');
      }
    });
  }
}
