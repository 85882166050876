import { Controller } from "@hotwired/stimulus"
import { Confetto, Sequin } from "../utils/confetti_logic";
import { toggleButtonState } from "../utils/buttons";

// Connects to data-controller="confetti-button"
export default class extends Controller {
  static targets = ["button", "canvas"];

  connect() {
    this.burstListener = this.initBurst.bind(this);
    document.addEventListener('initBurst', this.burstListener);

    this.confetti = [];
    this.sequins = [];

    this.colors = [
      { front: '#7b5cff', back: '#6245e0' }, // Purple
      { front: '#b3c7ff', back: '#8fa5e5' }, // Light Blue
      { front: '#5c86ff', back: '#345dd1' }  // Darker Blue
    ];

    this.confettiCount = 20
    this.sequinCount = 10
    this.gravityConfetti = 0.3;
    this.gravitySequins = 0.55;
    this.dragConfetti = 0.075;
    this.dragSequins = 0.02;
    this.terminalVelocity = 3;

    if (this.hasButtonTarget) {
      this.splitButtonText();

      // Setup the canvas context for drawing
      this.ctx = this.canvasTarget.getContext("2d");
      this.width = this.canvasTarget.width = window.innerWidth;
      this.height = this.canvasTarget.height = window.innerHeight;
    }
  }

  disconnect() {
    document.removeEventListener('initBurst', this.burstListener);
  }

  initBurst(event) {
    const { buttonId } = event.detail;

    if (buttonId === this.buttonTarget.id) {
      // Prevent burst if already running
      if (this.confetti.length > 0 || this.sequins.length > 0) return;

      // Initialize confetti and sequins
      for (let i = 0; i < this.confettiCount; i++) {
        this.confetti.push(new Confetto(this.buttonTarget, this.canvasTarget, this.colors, this.gravityConfetti, this.dragConfetti, this.terminalVelocity));
      }
      for (let i = 0; i < this.sequinCount; i++) {
        this.sequins.push(new Sequin(this.buttonTarget, this.canvasTarget, this.colors, this.gravitySequins, this.dragSequins));
      }

      // Start the animation loop
      requestAnimationFrame(this.render.bind(this));
    }
  }

  splitButtonText() {
    const textElements = this.buttonTarget.querySelectorAll('.button-text');

    textElements.forEach(element => {
      let characters = element.innerText.split('');
      let characterHTML = '';

      characters.forEach((letter, index) => {
        characterHTML += `<span class="char${index}" style="--d:${index * 30}ms; --dr:${(characters.length - index - 1) * 30}ms;">${letter}</span>`;
      });

      element.innerHTML = characterHTML;
    });
  }

  submit(event) {
    const submitButton = event.submitter || this.element.querySelector('.confetti-button');

    if (submitButton === this.buttonTarget) {
      const btnRect = this.buttonTarget.getBoundingClientRect();
      const btnCenterX = btnRect.left + btnRect.width / 2;
      const btnCenterY = btnRect.top + btnRect.height / 2;

      // Calculate the maximum size of the canvas
      const maxWidthLeft = btnCenterX;
      const maxWidthRight = window.innerWidth - btnCenterX;
      const maxHeightTop = btnCenterY;
      const maxHeightBottom = window.innerHeight - btnCenterY;

      const maxWidth = 2 * Math.min(maxWidthLeft, maxWidthRight);
      const maxHeight = 2 * Math.min(maxHeightTop, maxHeightBottom);

      // Set canvas size and position
      this.canvasTarget.width = maxWidth;
      this.canvasTarget.height = maxHeight;
      this.canvasTarget.style.top = `${btnCenterY - maxHeight / 2}px`;
      this.canvasTarget.style.left = `${btnCenterX - maxWidth / 2}px`;

      toggleButtonState(this.buttonTarget, true);
    }
  }

  render() {
    this.ctx.clearRect(0, 0, this.width, this.height);

    // Draw confetti and sequins
    this.confetti.forEach((confetto, index) => {
      confetto.update();
      confetto.draw(this.ctx);
      // Remove confetto if it's out of bounds
      if (confetto.isOutOfBounds(this.width, this.height)) {
        this.confetti.splice(index, 1);
      }
    });

    this.sequins.forEach((sequin, index) => {
      sequin.update();
      sequin.draw(this.ctx);
      // Remove sequin if it's out of bounds
      if (sequin.isOutOfBounds(this.width, this.height)) {
        this.sequins.splice(index, 1);
      }
    });

    // Continue the loop
    if (this.confetti.length > 0 || this.sequins.length > 0) {
      requestAnimationFrame(this.render.bind(this));
    }
  }
}
