import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="signature"
export default class extends Controller {
  static targets = ["pad", "data", "error", "placeholder"]

  async connect() {
    const { default: SignaturePad } = await import("signature_pad");
    
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(this.padTarget);

    this.boundStartDrawing = this.startDrawing.bind(this);

    this.padTarget.addEventListener('mousedown', this.boundStartDrawing);
    this.padTarget.addEventListener('touchstart', this.boundStartDrawing);
    this.padTarget.addEventListener('pointerdown', this.boundStartDrawing);
    window.addEventListener('resize', this.resizeCanvas.bind(this));
  }

  disconnect() {
    this.padTarget.removeEventListener('mousedown', this.boundStartDrawing);
    this.padTarget.removeEventListener('touchstart', this.boundStartDrawing);
    this.padTarget.removeEventListener('pointerdown', this.boundStartDrawing);
    window.removeEventListener('resize', this.resizeCanvas.bind(this));
  }

  startDrawing() {
    this.placeholderTarget.classList.add('d-none');
  }

  submitSignature(event) {
    if (this.signaturePad.isEmpty()) {
      event.preventDefault();
      event.stopPropagation();
      this.errorTarget.classList.remove('d-none');
    } else {
      this.dataTarget.value = this.signaturePad.toDataURL();
      this.errorTarget.classList.add('d-none');
    }
  }

  clearSignature() {
    const context = this.padTarget.getContext('2d');
    context.clearRect(0, 0, this.padTarget.width, this.padTarget.height);
    this.placeholderTarget.classList.remove('d-none');
  }

  resizeCanvas() {
    const containerWidth = this.padTarget.closest('.position-relative').offsetWidth;
  
    if (containerWidth < 400) {
      this.padTarget.width = containerWidth;
      this.padTarget.height = (5 / 8) * containerWidth;
    } else {
      this.padTarget.width = 400; // Example default width
      this.padTarget.height = 250; // Example default height (5/8 of 400)
    }
  }
}
