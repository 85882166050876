import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autocomplete"
export default class extends Controller {
  static targets = ["input", "suggestions", "error"];
  static values = { resourceType: String }

  connect() {
    this.selectedSuggestionIndex = -1;
  }

  search() {
    this.inputTarget.classList.toggle('search-address-invalid', true);
    let query = this.inputTarget.value;
    let minQueryLength = 3;

    if (query.length < minQueryLength) {
      this.clearSuggestions();
      return;
    }

    fetch(`/${window.currentLocale}/${this.resourceTypeValue}/autocomplete?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(data => this.displaySuggestions(data));
  }

  displaySuggestions(data) {
    this.clearSuggestions();
    data.forEach((item) => {
      let listItem = document.createElement('li');
      listItem.textContent = item;
      listItem.addEventListener('click', () => {
        this.inputTarget.value = listItem.textContent;
        this.inputTarget.classList.toggle('search-address-invalid', false);
        if (this.hasErrorTarget) {  // Check if the error target exists
          this.errorTarget.classList.add('d-none'); // Hide the error message
        }
        this.clearSuggestions();
      });
      this.suggestionsTarget.appendChild(listItem);
    });
  }

  clearSuggestions() {
    this.suggestionsTarget.innerHTML = '';
  }

  navigate(e) {
    let suggestions = this.suggestionsTarget.querySelectorAll('li');
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex + 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'ArrowUp':
        if (this.selectedSuggestionIndex <= 0) this.selectedSuggestionIndex = suggestions.length;
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex - 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'Enter':
        if (this.selectedSuggestionIndex > -1) {
          e.preventDefault(); // Prevent form submission
          suggestions[this.selectedSuggestionIndex].click();
        }
        break;
    }
  }

  highlightSuggestion(suggestions) {
    suggestions.forEach((suggestion, index) => {
      suggestion.classList.toggle('selected', index === this.selectedSuggestionIndex);
    });
  }

  hideSuggestions() {
    setTimeout(() => {
      this.clearSuggestions();
    }, 150);
  }
}
