// confetti_logic.js
export const randomRange = (min, max) => Math.random() * (max - min) + min;

export const initConfettoVelocity = (xRange, yRange) => {
    const x = randomRange(xRange[0], xRange[1]);
    const range = yRange[1] - yRange[0] + 1;
    let y = yRange[1] - Math.abs(randomRange(0, range) + randomRange(0, range) - range);
    if (y >= yRange[1] - 1) {
        // Occasional confetto goes higher than the max
        y += Math.random() < 0.25 ? randomRange(1, 3) : 0;
    }
    return { x, y: -y };
};

export class Confetto {
    constructor(button, canvas, colors, gravityConfetti, dragConfetti, terminalVelocity) {
        this.button = button;
        this.randomModifier = randomRange(0, 99);
        this.color = colors[Math.floor(randomRange(0, colors.length))];
        this.dimensions = {
            x: randomRange(5, 9),
            y: randomRange(8, 15),
        };
        this.position = {
            x: randomRange(canvas.width / 2 - button.offsetWidth / 4, canvas.width / 2 + button.offsetWidth / 4),
            y: randomRange(canvas.height / 2 + button.offsetHeight / 2 + 8, canvas.height / 2 + 1.5 * button.offsetHeight - 8),
        };
        this.rotation = randomRange(0, 2 * Math.PI);
        this.scale = { x: 1, y: 1 };
        this.velocity = initConfettoVelocity([-9, 9], [6, 11]);
        this.gravity = gravityConfetti;
        this.drag = dragConfetti;
        this.terminalVelocity = terminalVelocity;
    }

    update() {
        this.velocity.x -= this.velocity.x * this.drag;
        this.velocity.y = Math.min(this.velocity.y + this.gravity, this.terminalVelocity);
        this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();
        
        this.position.x += this.velocity.x;
        this.position.y += this.velocity.y;

        this.scale.y = Math.cos((this.position.y + this.randomModifier) * 0.09);
    }

    draw(ctx) {
        let width = this.dimensions.x * this.scale.x;
        let height = this.dimensions.y * this.scale.y;

        ctx.translate(this.position.x, this.position.y);
        ctx.rotate(this.rotation);

        ctx.fillStyle = this.scale.y > 0 ? this.color.front : this.color.back;
        ctx.fillRect(-width / 2, -height / 2, width, height);

        ctx.setTransform(1, 0, 0, 1, 0, 0);

        if (this.velocity.y < 0) {
            ctx.clearRect(-this.button.offsetWidth / 2, this.button.offsetHeight / 2, this.button.offsetWidth, this.button.offsetHeight);
        }
    }

    isOutOfBounds(width, height) {
        return this.position.y >= height;
    }
}

export class Sequin {
    constructor(button, canvas, colors, gravitySequins, dragSequins) {
        this.button = button;
        this.color = colors[Math.floor(randomRange(0, colors.length))].back;
        this.radius = randomRange(1, 2);
        this.position = {
            x: randomRange(canvas.width / 2 - button.offsetWidth / 3, canvas.width / 2 + button.offsetWidth / 3),
            y: randomRange(canvas.height / 2 + button.offsetHeight / 2 + 8, canvas.height / 2 + 1.5 * button.offsetHeight - 8),
        };
        this.velocity = {
            x: randomRange(-6, 6),
            y: randomRange(-8, -12),
        };
        this.gravity = gravitySequins;
        this.drag = dragSequins;
    }

    update() {
        this.velocity.x -= this.velocity.x * this.drag;
        this.velocity.y += this.gravity;
        
        this.position.x += this.velocity.x;
        this.position.y += this.velocity.y;
    }

    draw(ctx) {
        ctx.translate(this.position.x, this.position.y);

        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(0, 0, this.radius, 0, 2 * Math.PI);
        ctx.fill();

        ctx.setTransform(1, 0, 0, 1, 0, 0);

        if (this.velocity.y < 0) {
            ctx.clearRect(-this.button.offsetWidth / 2, this.button.offsetHeight / 2, this.button.offsetWidth, this.button.offsetHeight);
        }
    }

    isOutOfBounds(width, height) {
        return this.position.y >= height;
    }
}
