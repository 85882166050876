import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="label"
export default class extends Controller {
  static targets = ["link"]
  static values = { message: String }

  connect() {
    if (this.hasMessageValue && this.messageValue) {
      this.displayNotification(this.messageValue);
      this.clearFormFields();
    }
  }

  async displayNotification(message) {
    const { default: Swal } = await import('sweetalert2');

    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
  }

  clearFormFields() {
    const fields = document.querySelectorAll("[id^='label_name']");

    fields.forEach((field) => {
      field.value = ''; 
    });
  }

  toggleClass(event) {
    this.linkTargets.forEach((element) => {
      element.classList.remove('label-link-active');
    });

    event.currentTarget.classList.add('label-link-active');
  }
}
