import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-bar"
export default class extends Controller {
  static targets = ["locationInput", "priceInput", "bedroomsInput"]

  expandLocationInput() {
    this.priceInputTarget.classList.add('d-none');
    this.bedroomsInputTarget.classList.add('d-none');
    this.locationInputTarget.classList.toggle('d-none');
  }

  expandPriceInput() {
    this.locationInputTarget.classList.add('d-none');
    this.bedroomsInputTarget.classList.add('d-none');
    this.priceInputTarget.classList.toggle('d-none');
  }

  expandBedroomsInput() {
    this.locationInputTarget.classList.add('d-none');
    this.priceInputTarget.classList.add('d-none');
    this.bedroomsInputTarget.classList.toggle('d-none');
  }
}