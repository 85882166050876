import { Controller } from "@hotwired/stimulus"
import { loadCss } from "../utils/css_loader"
import { closeModal, fetchAndDisplayModal } from "../utils/modal_helpers"

// Connects to data-controller="image-upload"
export default class extends Controller {
  static targets = ["preview", "input", "error"]
  static values = { existingData: Array, aspectRatio: Number, minHeight: Number, crop: Boolean, multiple: Boolean }

  images = []

  async connect() {
    this.fileIndex = 0;

    this.initializeCropperInModalBound = this.initializeCropperInModal.bind(this);
    document.addEventListener('show.bs.modal', this.initializeCropperInModalBound);

    this.clickListener = async (event) => {
      if (event.target.id === 'crop-confirm-button') {
        await this.finalizeCrop();
      }
    };

    document.addEventListener('click', this.clickListener);

    if (this.multipleValue) {
      await this.initializeSortable()
    }

    this.initializeExistingImages();
  }

  disconnect() {
    // Remove the event listener when the controller disconnects
    document.removeEventListener('show.bs.modal', this.initializeCropperInModalBound);
    document.removeEventListener('click', this.clickListener);
  }

  async initializeSortable() {
    const Sortable = (await import('sortablejs')).default;

    new Sortable(this.previewTarget, {
      animation: 150,
      ghostClass: 'sort-placeholder',
      onUpdate: () => this.updateSortedImages()
    })
  }

  updateSortedImages() {
    let updatedImages = []
    this.previewTarget.querySelectorAll('.image-container img').forEach((img, index) => {
      let fileIndex = parseInt(img.dataset.fileIndex)
      updatedImages.push(this.images[fileIndex])
      img.dataset.fileIndex = index
    })
    this.images = updatedImages;
    this.dispatchUpdatedImagesEvent();
  }

  removeImage(event) {
    const imageContainer = event.target.closest('.image-container');
    const img = imageContainer.querySelector('img');
    const fileIndex = parseInt(img.dataset.fileIndex);
  
    this.images[fileIndex] = null;
    this.dispatchUpdatedImagesEvent();
    imageContainer.remove();
  }

  inputChange(event) {
    const files = event.target.files;

    this.errorTarget.classList.add('d-none');

    if (this.cropValue && files.length > 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Directly set the preview with the image to crop
        this.imageSrc = e.target.result;
        fetchAndDisplayModal("image_cropper");
      };
  
      reader.readAsDataURL(files[0]);
    } else {
      Array.from(files).forEach(file => {
        this.createImagePreview(file);
      })
    }

    event.target.value = '';
  }

  async createImagePreview(input, signedId = null, fullSizeUrl = null) {
    const imageContainer = document.createElement('div');
    imageContainer.className = 'image-container';

    const img = document.createElement('img');
    img.className = "listing-nav-thumb";
    img.setAttribute('data-file-index', this.fileIndex.toString());
    this.fileIndex++;

    const removeButton = document.createElement('button');
    removeButton.type = "button";
    removeButton.className = "btn-remove-image";
    removeButton.setAttribute("aria-label", "Remove image");
    removeButton.textContent = 'x';
    removeButton.dataset.action = "click->image-upload#removeImage";
    imageContainer.appendChild(removeButton);

    const link = document.createElement('a');
    link.target = '_blank';
    link.appendChild(img);
    imageContainer.appendChild(link);
    this.updateImagePreview(imageContainer);

    if (input instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target.result;
        link.href = e.target.result;
        this.updateImages(input); 
      };
      reader.readAsDataURL(input);
    } else if (this.isDataURL(input)) { // Check if input is a Data URL
      img.src = input;
      link.href = input;
      const response = await fetch(input);
      this.updateImages(await response.blob());
    } else {
      img.src = input;
      link.href = fullSizeUrl;
      this.updateImages(signedId);
    }    
  }

  updateImagePreview(imageContainer) {
    if (!this.multipleValue) {
      this.previewTarget.innerHTML = ''; // Clear existing content for single image mode
    }

    this.previewTarget.appendChild(imageContainer);
  }
  
  updateImages(input) {
    if (this.multipleValue) {
      this.images.push(input);
    } else {
      this.images = [input]; // Replace with new image for single image mode
    }
  
    this.dispatchUpdatedImagesEvent();
  }

  initializeExistingImages() {
    if (this.hasExistingDataValue) {
      this.existingDataValue.forEach(photoData => {
        this.createImagePreview(photoData.thumbUrl, photoData.signedId, photoData.fullSizeUrl);
      });
    }
  }

  // Initializes Cropper.js on the image in the modal
  async initializeCropperInModal(event) {
    if (event.target.getAttribute('data-modal-type') === 'image_cropper' && this.imageSrc) {
      const image = event.target.querySelector("#modal-image-to-crop");
      if (image) {
        image.src = this.imageSrc; // Set the uploaded image as the source for cropping
        
        try {
          await this.waitForImageDisplay(image);
          // Now that the image is displayed as expected, initialize Cropper
          this.setupCropper(image);
        } catch (error) {
          console.error("Error preparing image for cropping:", error);
        }
      }
    }
  }

  async waitForImageDisplay(image, timeout = 5000) {
    return new Promise((resolve, reject) => {
      let elapsed = 0;
      const interval = 100;
  
      const checkCondition = () => {
        // Check if the image has reached its expected conditions
        if (image.complete && image.naturalWidth > 0 && image.offsetWidth > 0) {
          resolve();
        } else if (elapsed >= timeout) {
          reject(new Error("Timed out waiting for image to be displayed"));
        } else {
          elapsed += interval;
          setTimeout(checkCondition, interval);
        }
      };
  
      checkCondition();
    });
  }

  async setupCropper(image) {
    const CropperModule = await import('cropperjs');
    const Cropper = CropperModule.default;
    await loadCss('/cropper.css');

    const aspectRatio = this.hasAspectRatioValue ? this.aspectRatioValue : (4 / 3);

    // Initialize Cropper.js on the image
    this.cropper = new Cropper(image, {
      aspectRatio: aspectRatio,
      autoCropArea: 1
      // additional options here
    });
  }

  async finalizeCrop() {
    if (this.cropper && this.imageSrc) {
      const croppedCanvas = this.cropper.getCroppedCanvas();

      if (this.hasMinHeightValue && croppedCanvas.height < this.minHeightValue) {
        this.errorTarget.classList.remove('d-none');
      } else {
        this.createImagePreview(croppedCanvas.toDataURL('image/jpeg'))
      }
      
      this.imageSrc = null;
      closeModal();
    }
  }

  dispatchUpdatedImagesEvent() {
    if (this.multipleValue) {
      this.dispatchUpdatedMultipleImagesEvent();
    } else {
      this.dispatchUpdatedSingleImageEvent();
    }
  }

  dispatchUpdatedSingleImageEvent() {
    const event = new CustomEvent('imageUpload:singleImageUpdated', {
      detail: { file: this.images[0] },
      bubbles: true, // Ensure the event bubbles up through the DOM
    });
    this.element.dispatchEvent(event);
  }

  dispatchUpdatedMultipleImagesEvent() {
    const event = new CustomEvent('imageUpload:multipleImagesUpdated', {
      detail: { files: [...this.images] },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  isDataURL(str) {
    return typeof str === 'string' && str.startsWith('data:');
  }
}
