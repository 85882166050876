import { Controller } from "@hotwired/stimulus"
import { toggleButtonState } from "../utils/buttons";


// Connects to data-controller="confetti-button"
export default class extends Controller {
  static values = { id: String }

  connect() {
    this.button = document.getElementById(this.idValue);
    this.resetButton();
  }

  resetButton() {
    toggleButtonState(this.button, false);
  }
}
