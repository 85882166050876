import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = { content: String }
  connect() {
    this.tooltip = new bootstrap.Tooltip(this.element, {
      title: () => this.contentValue,  // Use static content
      html: true,
      trigger: 'manual' // Manual trigger to control via click
    });
  }

  show(event) {
    event.preventDefault();
    this.tooltip.show();
    this.startHideTimer();
  }

  hide() {
    this.tooltip.hide();
  }

  startHideTimer() {
    clearTimeout(this.hideTimer);
    this.hideTimer = setTimeout(() => {
      this.hide();
    }, 5000);
  }

  disconnect() {
    clearTimeout(this.hideTimer);
  }
}
