import { calculateTypingDelay, extractConversationDetails, generateMessageHTML, setLocalStorageItem, storeConversationDetails, updateChatOverview, updateMessageStatus } from "../utils/chat";
import consumer from "./consumer"

function createChatChannel(conversationDetails) {
  return consumer.subscriptions.create({ 
    channel: "ChatChannel",
    conversation_details: conversationDetails,
    current_locale: window.currentLocale
  }, {
    connected() {
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      const conversationDetails = extractConversationDetails(document.getElementById('chat-window'));

      if (data.conversationDetails.id === conversationDetails.id) {
        switch (data.action) {
          case 'update_status':
            updateMessageStatus(document.getElementById('chat-window'), data.id, data.status)
            break;
          case 'new_message':
            const messageHTML = generateMessageHTML(data);
            if (data.isAutomated) { // Check if the message is from the bot
              const botTyping = document.getElementById('mable-typing');
              botTyping.classList.remove('d-none'); // Ensure 'd-none' is the class that hides the element
              const delay = calculateTypingDelay();
              const messagesContainer = document.getElementById('messages');
              messagesContainer.scrollTop = messagesContainer.scrollHeight;
              setTimeout(() => {
                  botTyping.classList.add('d-none'); // Hide the typing indicator after 2 seconds
                  this.appendMessageHTML(messageHTML); // Append the message after the delay
              }, delay);
            } else {
                this.appendMessageHTML(messageHTML); // If not from the bot, append immediately
            }
            updateChatOverview(data);
            storeConversationDetails(document.getElementById('chat-window'), data.conversationDetails);
            document.getElementById('new-message-form').classList.toggle('d-none', data.conversationDetails.status === 'closed');
            break;
        }
      }
    },

    speak: function(content, imageFile = null) {
      const data = { content: content };

      const conversationDetails = extractConversationDetails(document.getElementById('chat-window'));
      if (conversationDetails.status === 'automated') {
        const botTyping = document.getElementById('mable-typing');
        botTyping.classList.remove('d-none');
        setLocalStorageItem('typing_start_time', new Date().getTime());
      }

      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          data.image = e.target.result;
          this.perform('speak', data);
        };
        reader.readAsDataURL(imageFile);
      } else {
        this.perform('speak', data);
      }
    },

    appendMessageHTML: function(messageHTML) {
      const messagesContainer = document.getElementById('messages');
      if (messagesContainer) {
        messagesContainer.insertAdjacentHTML('beforeend', messageHTML);
        const newMessageElement = messagesContainer.lastElementChild;

        setTimeout(() => {
          newMessageElement.classList.add('message-enter-active');
        }, 10);

        messagesContainer.scrollTop = messagesContainer.scrollHeight; // Scroll to the bottom

        const images = newMessageElement.querySelectorAll('img');
        images.forEach(img => {
          img.onload = () => {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
          };
        });
      }
    },
  });
}

function createGeneralChatChannel() {
  return consumer.subscriptions.create({
    channel: "ChatChannel",
    // Passing minimal or no parameters since this is a general listener
  }, {
    received(data) {
      switch (data.action) {
        case 'update_status':
          updateMessageStatus(document.getElementById('chat-overview'), data.id, data.status)
          break;
        case 'new_message':
          const chatOverview = document.getElementById('chat-overview');
          const chatWindow = document.getElementById('chat-window');

          // Check if 'chat-overview' and 'chat-window' have 'd-none'
          const isOverviewHidden = chatOverview.classList.contains('d-none');
          const isWindowHidden = chatWindow.classList.contains('d-none');

          // Always update chat-overview regardless of its visibility
          updateChatOverview(data);

          // Determine visibility action based on the state of 'chat-window'
          if (isWindowHidden) {
            // If the chat window is hidden, show chat-overview
            if (isOverviewHidden) {
              chatOverview.classList.remove('d-none');
            }
          } else {
            // If the chat window is visible, do not disturb the user
            // Maybe highlight or badge the chat-overview to indicate new message
            // indicateChangeInOverview();
          }
          break;
      }
    }
  });
}

export { createChatChannel, createGeneralChatChannel };