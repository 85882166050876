import { Controller } from "@hotwired/stimulus"
import { loadCss } from "../utils/css_loader";

// Connects to data-controller="datetime-picker"
export default class extends Controller {
  async connect() {
    const flatpickr = await import('flatpickr');
    await loadCss('/flatpickr.min.css');

    flatpickr.default(this.element, {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      time_24hr: true
    });

    // this.element.addEventListener('focus', function() {
    //  this.element.blur();
    // });

    this.element.readOnly = false;
  }
}
