import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-filter"
export default class extends Controller {
  static targets = ["dropdown", "submitButton"];

  toggle(event) {
    const currentDropdown = event.currentTarget.closest('.dropdown');

    // Toggle the current dropdown
    const isVisible = currentDropdown.querySelector('.angle-down-icon').classList.contains('d-none');
    currentDropdown.querySelector('.dropdown-toggle').classList.toggle('border-bottom');

    if (isVisible) {
      this.dropdownTargets.forEach(dropdown => {
        dropdown.classList.remove('d-none');
      });
    } else {
      this.dropdownTargets.forEach(dropdown => {
        if (dropdown !== currentDropdown) {
          dropdown.classList.add('d-none');
        }
      });
    }

    currentDropdown.querySelector('.angle-down-icon').classList.toggle('d-none');
    currentDropdown.querySelector('.angle-up-icon').classList.toggle('d-none');
    this.submitButtonTarget.classList.toggle('border-top');
  }
}