const translations = {
    "en": {
      "are_you_sure": "Are you sure?",
      "yes": "Yes",
      "no": "No",
      "view_details": "View Details",
    },
    "zh-HK": {
      "are_you_sure": "您確定嗎？",
      "yes": "是",
      "no": "否",
      "view_details": "查看詳情",
    },
    "zh-CN": {
      "are_you_sure": "您确定吗？",
      "yes": "是",
      "no": "否",
      "view_details": "查看详情",
    },
};

export default translations;