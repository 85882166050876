export function updateButtonState(button, isDisabled) {
    if (button) {
        button.disabled = isDisabled;
        button.classList.toggle('disabled', isDisabled);
    }
}

export function toggleButtonState(button, isLoading) {
    
    if (button instanceof HTMLElement) {
        button.disabled = isLoading;
        if (isLoading) {
            button.classList.add('loading');
            button.classList.remove('ready');
        } else {
            button.classList.add('ready');
            button.classList.remove('loading');
            button.classList.remove('complete');
        }
    } else {
        console.warn("toggleButtonState was called with an invalid button element", button);
    }
}