import { Controller } from "@hotwired/stimulus"
import noUiSlider from 'nouislider/dist/nouislider.mjs';

// Connects to data-controller="slider"
export default class extends Controller {
  static values = {
    start: Array,
    min: Number,
    max: Number,
    step: { type: Number, default: 1 },
    isCurrency: Boolean,
    range: Object,
    subtitleId: String
  }

  static targets = ["slider", "minInput", "maxInput"]

  connect() {
    const effectiveRange = this.hasRangeValue ? this.rangeValue : { 'min': this.minValue, 'max': this.maxValue };

    noUiSlider.create(this.sliderTarget, {
      start: this.startValue,
      connect: true,
      range: effectiveRange,
      step: this.stepValue,
      tooltips: [true, true],
      format: this.isCurrencyValue ? this.currencyFormat() : this.defaultFormat()
    });

    this.sliderTarget.noUiSlider.on('slide', (rawValues, handle, unencoded) => {
      this.updateValues(rawValues, unencoded);
    });
  }

  updateValues(rawValues, unencoded) {
    this.minInputTarget.value = unencoded[0];
    this.maxInputTarget.value = unencoded[1];
    const subtitleElement = document.getElementById(this.subtitleIdValue);

    if (subtitleElement) {
      subtitleElement.textContent = `${rawValues[0]} - ${rawValues[1]}`;
    }
  }

  disconnect() {
    if (this.sliderTarget.noUiSlider) {
      this.sliderTarget.noUiSlider.destroy();
    }
  }

  defaultFormat() {
    return {
      to: function(value) {
        return Math.round(value).toString();
      },
      from: function(value) {
        return parseFloat(value);
      }
    };
  }

  currencyFormat() {
    return {
      to: function(value) {
        return `$${(value / 1000).toFixed(0)}k`;
      },
      from: function(value) {
        return parseFloat(value.replace(/\$\s?|k/g, '')) * 1000;
      }
    };
  }
}
